<script>
import * as HmWxUtils from '/@/utils/hmWxUtils.js';
export default {
  onLaunch: function () {
    console.log('App Launch')
    HmWxUtils.uniStorage();
  },
  onShow: function () {
    console.log('App Show')
  },
  onHide: function () {
    console.log('App Hide')
  },
}
</script>

<style lang="scss">
/*每个页面公共css */
@import "./uni_modules/vk-uview-ui/index.scss";
@import "./static/css/index.css";
@import "./static/colorui/main.css";
@import "./static/colorui/icon.css";
@import "./static/css/index-app.css";
</style>
