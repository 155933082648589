import {
    createSSRApp
} from "vue";
import App from "./App.vue";
/* #ifdef H5 */
import "./static/css/index.css";
/* #endif */

import uView from './uni_modules/vk-uview-ui';

// #ifdef H5
import loadComponent from './utils/loadComponent';
// #endif

import "./mock/index.js";
import config from "./common/config";
import {Base64} from 'js-base64'

import {
    map,
    filter,
    reduce,
    array2tree
} from './utils/flowUtils';

import {
    getAction,
    downloadFile,
    postAction,
    deleteAction,
    putAction,
} from "./request/http";

// #ifdef H5
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
import * as dingTalkJsApi from 'dingtalk-jsapi';
// #endif
 

export function createApp() {
    const app = createSSRApp(App);
    app.config.globalProperties.$getAction = getAction;
    app.config.globalProperties.$downloadFile = downloadFile;
    app.config.globalProperties.$postAction = postAction;
    app.config.globalProperties.$deleteAction = deleteAction;
    app.config.globalProperties.$putAction = putAction;

    app.config.globalProperties.$map = map;
    app.config.globalProperties.$filter = filter;
    app.config.globalProperties.$reduce = reduce;
    app.config.globalProperties.$array2tree = array2tree;

    app.config.globalProperties.$getCssUnit = function (value) {
        if (isNaN(Number(value))) {
            return value;
        }
        return `${value}rpx`;
    }
    app.config.globalProperties.$config = config;
    app.config.globalProperties.$Base64 = Base64;
    // #ifdef H5
    app.config.globalProperties.$dingTalkJsApi = dingTalkJsApi;
    // #endif

    // app.use(message);
    app.use(uView);

    //加载组件
    // #ifdef H5
    //app.use(vConsole);
    app.use(loadComponent);
    // #endif

    return {
        app,
    };
}
